import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Header = () => (
    <header className="header home py-5">
      <div className="logo-wrapper">
        <h1><span className="title-frag-1">Bring</span> <span className="title-frag-2">Me The</span> <span className="title-frag-3">Axe</span></h1>
        <div className="skull">
          <StaticImage
            src="../images/axes.png"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Bring Me The Axe Horror Podcast Logo"
            className="img-fluid"
            placeholder="BLURRED"
          />
        </div>
      </div>
    </header>
)

export default Header