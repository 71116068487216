import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const AllEpisodes = () => (
    <section className="py-5 text-center containe latest-episodes black-band">
      <div className="container">
        <div className="row">
          <div className="col section-header">
            <h1>All Episodes</h1>
          </div>
        </div>
        <div className="row py-5">
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/night-of-the-creeps">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Night of the Creeps</h1>
                            <h2 className="episode-number">37</h2>
                            <div className="episode-body">
                                <p>1986 // Directed by Fred Dekker // Starring Jason Lively &amp; Tom Atkins</p>
                                <p>Bryan and Dave take a good long look at Fred Dekker's, Night of the Creeps and come away with a vastly different view of it than when they watched it as kids. It's a pastiche of 50's and 60's drive in tropes filtered through a 1980's horror movie lens but unfortunately hasn't aged well at all. Thankfully, Tom Atkins is here to save everything. Thrill me!</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/night-of-the-creeps-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1986 zombie movie, Night of the Creeps"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/dawn-of-the-dead">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Dawn of the Dead</h1>
                            <h2 className="episode-number">36</h2>
                            <div className="episode-body">
                                <p>1978 // Directed by George Romero // Starring Ken Foree &amp; Gaylen Ross</p>
                                <p>Bryan and Dave celebrate one year of podcasting with a real deep dive into George Romero's paradigm shifting zombie epic, Dawn of the Dead. In the history of horror movies, there are only a few movies that changed the way movies are made and viewed and this is one of them.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/dawn-of-the-dead-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1978 zombie movie, Dawn of the Dead"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/my-bloody-valentine">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">My Bloody Valentine (Bonus)</h1>
                            <h2 className="episode-number">35</h2>
                            <div className="episode-body">
                                <p>1981 // Directed by George Mihalka // Starring Paul Kelman &amp; Neil Affleck</p>
                                <p>Dave and Bryan love you and have sent you this Valentine's Day love letter to 1981's Canadian slasher movie classic, My Bloody Valentine. Sure, it's another slice and dice picture from the golden age of slashers but not too many of them paid this much attention to the craft of telling a story.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/my-bloody-valentine-poster-sm.jpg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1981 slasher movie, My Bloody Valentine"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/ganja-and-hess">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Ganja and Hess</h1>
                            <h2 className="episode-number">34</h2>
                            <div className="episode-body">
                                <p>1973 // Directed by Bill Gunn // Starring Duane Jones &amp; Marlene Clark</p>
                                <p>Dave and Bryan celebrate Black History Month with a look at Bill Gunn's delirious 1973 vampire movie, Ganja & Hess, which stars Gunn, Marlene Clark, and Duane Jones from Night of the Living Dead. Often lumped in with blaxploitation movies like Blacula and Shaft, it is anything but.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/ganja-and-hess-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1973 vampire art film, Ganja and Hess"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/x-ray">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">X-Ray</h1>
                            <h2 className="episode-number">33</h2>
                            <div className="episode-body">
                                <p>1982 // Directed by Boaz Davidson // Starring Barbi Benton</p>
                                <p>Bryan and Dave are joined by Jonny and Aileen from the Uy Que Horror podcast for a maddening breakdown of the Cannon Films deep cut, X-Ray (aka Hospital Massacre), starring Barbi Benton as a woman who just needs to pick up her check-up results and spends the rest of the day running from a slasher.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/x-ray-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1982 christmas slasher movie, X-Ray aka Hospital Massacre"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/fright-night">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Fright Night</h1>
                            <h2 className="episode-number">32</h2>
                            <div className="episode-body">
                                <p>1985 // Directed by Tom Holland // Starring William Ragsdale &amp; Chris Sarandon</p>
                                <p>Bryan and Dave are joined this week by drag performer extraordinaire, Peaches Christ, to talk about the arch-80's vampire classic from 1985, Fright Night, starring William Ragsdale, Roddy McDowall, and Chris Sarandon (making his second appearance on the pod). It's an awesome movie that could have been even better for reasons we get deep in the weeds over.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/fright-night-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1985 vampire movie, Fright Night"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/new-years-evil">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">New Year's Evil</h1>
                            <h2 className="episode-number">31</h2>
                            <div className="episode-body">
                                <p>1980 // Directed by Emmet Alston // Starring Roz Kelly &amp; Kip Niven</p>
                                <p>Bryan and Dave kick off the new year and hit peak holiday horror movie burnout when they go deep with the 1980 slasher movie, New Year's Evil. More like a giallo than anything else, it seems to possess a profound misunderstanding of what audiences were expecting from a slasher movie and delivers some seriously silly murder setpieces.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/new-years-evil-poster-sm.jpg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1980 slasher movie, New Year's Evil"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/black-christmas">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Black Christmas</h1>
                            <h2 className="episode-number">30</h2>
                            <div className="episode-body">
                                <p>1974 // Directed by Bob Clark // Starring Margot Kidder &amp; Olivia Hussey</p>
                                <p>Bryan and Dave close out their holiday season series with a real deep dive into Bob Clark's masterpiece, Black Christmas. It's the prototype for the slasher movies that followed. It's a movie stuffed with a diabolical eye for detail and unbearable tension.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/black-christmas-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1974 christmas horror movie, Black Christmas"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/2023-roundup">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">2023 Roundup</h1>
                            <h2 className="episode-number">29</h2>
                            <div className="episode-body">
                                <p>This week Bryan and Dave take a look back at Bring Me The Axe! year one and all the horror movies released in 2023. They run down the losers, the winners, and the just okay. Find out where all your favorite horror movies of this year landed and prepare to be outraged by hot takes and surprising reviews. Where did Scream 6 land? How do we feel about Skinamarink?</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/Episode292023HorrorRoundup-sm.png"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the Bring Me The Axe 2023 horror movie roundup"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/silent-night-deadly-night">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Silent Night, Deadly Night</h1>
                            <h2 className="episode-number">28</h2>
                            <div className="episode-body">
                                <p>1984 // Directed by Charles Sellier // Starring Robert Brian Wilson &amp; Lilyan Chauvin</p>
                                <p>This week, Bryan and Dave are joined by author, Joe Vallese, and hope that Santa will judge them to be good because they definitely don't want to get on his bad side as they dive head first into 1984's Santa slasher movie, Silent Night, Deadly Night.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/silent-night-deadly-night-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1984 christmas slasher movie, Silent Night, Deadly Night"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/hellraiser">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Hellraiser</h1>
                            <h2 className="episode-number">27</h2>
                            <div className="episode-body">
                                <p>1987 // Directed by Clive Barker // Starring Doug Bradley &amp; Ashley Laurence</p>
                                <p>Bryan and Dave have such sights to show you! This week they are joined by the co-host of the Morbid podcast, Alaina Urquhart, a demon to some, angel to others, to dive deep into one of her favorite movies and a real top-shelf of the horror canon, Clive Barker's Hellraiser.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/hellraiser-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1987 horror movie, Hellraiser"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/blood-rage">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Blood Rage</h1>
                            <h2 className="episode-number">26</h2>
                            <div className="episode-body">
                                <p>1987 // Directed by John Girssmer // Starring Louise Lasser &amp; Mark Soper</p>
                                <p>Just in time for Thanksgiving, Bryan and Dave dive head-first into the wildly inconsistent shit show that is Blood Rage. Marvel at the utterly deranged performance from Louise Lasser. That's not cranberry sauce.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/blood-rage-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1987 thanksgiving slasher movie, Blood Rage"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/elves">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Elves</h1>
                            <h2 className="episode-number">25</h2>
                            <div className="episode-body">
                                <p>1989 // Directed by Jeff Mandel // Starring Dan Haggerty &amp; Julie Austin</p>
                                <p>As we near the holiday season, Bryan and Dave darken your doorstep with the low-budget howling madness of 1989's Elves, a movie that under-delivers on elves and over-delivers on incest. If you've ever wondered what Jim Wynorski's version of Gremlins would be this one lands pretty close to the mark.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/elves-poster-sm.jpg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1989 Christmas horror movie, Elves"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/the-watcher-in-the-woods">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">The Watcher in the Woods (Bonus)</h1>
                            <h2 className="episode-number">24</h2>
                            <div className="episode-body">
                                <p>1980 // Directed by John Hough // Starring Lynn-Holly Johnson &amp; Bette Davis</p>
                                <p>Bryan and Dave are joined by Teri Gamble of the Horror Movie Survival Guide podcast to talk about Disney's bizarro 80's period where they experimented with more mature films resulting in one of their most easily-forgotten titles, The Watcher in the Woods.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/the-watcher-in-the-woods-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1980 Disney horror movie, The Watcher in the Woods"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/halloween-1978">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Halloween (1978)</h1>
                            <h2 className="episode-number">23</h2>
                            <div className="episode-body">
                                <p>1978 // Directed by John Carpenter // Starring Jamie Lee Curtis &amp; Donald Pleasance</p>
                                <p>Bring Me The Axe! concludes its exploration of the Halloween legacy just in time for Halloween with an epic-length love letter to John Carpenter, Debra Hill, and their landmark creation. Halloween represents a major paradigm shift in horror movie and sets the pace for every horror movie that came in its wake.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/halloween-1978-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1978 slasher movie classic, Halloween"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/halloween-2007">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Halloween (2007)</h1>
                            <h2 className="episode-number">22</h2>
                            <div className="episode-body">
                                <p>2007 // Directed by Rob Zombie // Starring Sheri Moon Zombie &amp; Tyler Mane</p>
                                <p>This week, Bryan and Dave are joined by The Horror Chick, Heather Wixson to get grimy and talk about Rob Zombie's arch-2000's horror movie remake, Halloween.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/halloween-2007-poster-sm.jpg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 2007 slasher remake, Halloween"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/friday-the-13th">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Friday the 13th (Bonus)</h1>
                            <h2 className="episode-number">21</h2>
                            <div className="episode-body">
                                <p>1980 // Directed by Sean Cunningham // Starring Adrienne King &amp; Betsy Palmer</p>
                                <p>Bryan and Dave square off against one of the all-time horror movie greats on the most appropriate calendar date of the year. It's 1980's Friday the 13th, a movie made by pornography producers looking to take away a little of that sweet Halloween money for themselves and accidentally set the pace for slasher movies in the 80's.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/friday-the-13th-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1980 slasher movie, Friday they 13th"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/halloween-2018">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Halloween (2018)</h1>
                            <h2 className="episode-number">20</h2>
                            <div className="episode-body">
                                <p>2018 // Directed by David Gordon Green // Starring Jamie Lee Curtis &amp; Judy Greer</p>
                                <p>Bring Me The Axe! kicks off their month-long examination of the Halloween with David Gordon Green's 2018 series revision. It's a deeply flawed movie that's way better than you expect a Halloween sequel or remake to be and it reduces the series to its roots while whiffing on move than a few points.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/halloween-2018-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 2018 slasher movie, Halloween"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/dont-panic">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Don't Panic (Bonus)</h1>
                            <h2 className="episode-number">19</h2>
                            <div className="episode-body">
                                <p>1987 // Directed by Ruben Galindo, Jr. // Starring Jon Michael Bischof &amp; Gabriela Hassel</p>
                                <p>Bryan and Dave take a trip to Mexico City to celebrate their favorite Ruben Galindo Jr. movie, Don't Panic. It's a blatant a cash-in on the success of Evil Dead and A Nightmare on Elm Street that has so many bizarre qualities to it that you can't help but love it.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/dont-panic-poster-sm.jpg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1987 Mexican horror movie, Don't Paic"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/the-peanut-butter-solution">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">The Peanut Butter Solution</h1>
                            <h2 className="episode-number">18</h2>
                            <div className="episode-body">
                                <p>1985 // Directed by Michael Rubbo // Starring Michael Hogan &amp; Mathew Mackay</p>
                                <p>Bryan and Dave close out their What The Fuck?? series with a children's movie that terrorized an entire generation in Canada, The Peanut Butter Solution. It's a tale of loss and woe when a child loses his hair due to a terrible fright and deals with it poorly.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/the-peanut-butter-solution-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1985 children's movie, The Peanut Butter Solution"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/burnt-offerings">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Burnt Offerings (Bonus)</h1>
                            <h2 className="episode-number">17</h2>
                            <div className="episode-body">
                                <p>1976 // Directed by Dan Curtis // Starring Oliver Reed and Karen Black</p>
                                <p>Bryan and Dave are joined by actor, comedian, and made-for-tv movie expert, Sam Pancake to talk about Dan Curtis (of Dark Shadows fame) and his 1976 gothic horror melodrama, Burnt Offerings.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/burnt-offerings-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1976 gothic horror, Burnt Offerings"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/the-baby">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">The Baby</h1>
                            <h2 className="episode-number">16</h2>
                            <div className="episode-body">
                                <p>1973 // Directed by Ted Post // Starring Ruth Roman and Anjanette Comer</p>
                                <p>Bryan and Dave kick off their What The Fuck??? series with Ted Posts deeply troubling, alienating, and off-putting thriller, The Baby, from 1973.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/the-baby-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1973 shocker, The Baby"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/silver-bullet">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">Silver Bullet</h1>
                          <h2 className="episode-number">15</h2>
                          <div className="episode-body">
                              <p>1985 // Directed by Daniel Attias // Starring Corey Haim and Everett McGill</p>
                              <p>Bryan and Dave are joined by Julia Marchese to talk about the kids vs. werewolf picture that ended the Dino De Laurentis/Stephen King dynasty in the 1980's, Silver Bullet.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/silver-bullet-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1985 werewolf movie, Silver Bullet"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/maximum-overdrive">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">Maximum Overdrive</h1>
                          <h2 className="episode-number">14</h2>
                          <div className="episode-body">
                              <p>1986 // Directed by Stephen King // Starring Emilio Estevez and Laura Harrington</p>
                              <p>This week kicks Bring Me The Axe! takes on Stephen King's undisputed document of cocaine confidence, Maximum Overdrive from 1986. It's an ill-advised adaptation of his own short story, Trucks, that features a lot explosions, big trucks, and Yeardley Smith screaming.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/maximum-overdrive-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1986 killer trucks movie, Maximum Overdrive"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/sleepaway-camp">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">Sleepaway Camp</h1>
                          <h2 className="episode-number">13</h2>
                          <div className="episode-body">
                              <p>1983 // Directed by Robert Hiltzik // Starring Felissa Rosa and Karen Fields</p>
                              <p>This week, Bring Me The Axe! heads to Camp Arawak to explore one of slasher history's most unhinged entries, Sleepaway Camp. Be advised, we spoil the twist ending that we spoil right from the get-go.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/sleepaway-camp-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1983 slasher movie, Sleepaway Camp"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/madman">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">Madman</h1>
                          <h2 className="episode-number">12</h2>
                          <div className="episode-body">
                              <p>1981 // Directed by Joe Giannone // Starring Gaylen Ross and Tony Nunziata</p>
                              <p>This week Bring Me The Axe takes a look at 1981's Madman, a truly weird entry into the early 80's slasher canon. A cast of pleasant camp counselors at Thanksgiving Gifted Camp face off against an axe murderer.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/madman-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1981 slasher movie, Madman"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/jaws">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">Jaws (Bonus)</h1>
                          <h2 className="episode-number">11</h2>
                          <div className="episode-body">
                              <p>1975 // Directed by Steven Spielberg // Starring Roy Scheider and Richard Dreyfus</p>
                              <p>It's a holiday bonus episode for July 4th! Celebrating Steven Spielberg's landmark accomplishment and one of the all-time greatest movies, Jaws!</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/jaws-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1975 killer shark movie, Jaws"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/the-old-dark-house">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">The Old Dark House</h1>
                          <h2 className="episode-number">10</h2>
                          <div className="episode-body">
                              <p>1932 // Directed by James Whale // Starring Boris Karloff and Ernest Thesiger</p>
                              <p>Bring Me The Axe closes out their Pride 2023 series with an enthusiastic love letter to James Whale's 1932 pre-code classic for Universal, The Old Dark House.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/the-old-dark-house-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1932 gothic horror, The Old Dark House"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/vamp">
                <div className="episode">
                    <div className="episode-wrapper">
                        <h1 className="episode-title">Vamp</h1>
                        <h2 className="episode-number">09</h2>
                        <div className="episode-body">
                            <p>1986 // Directed by Richard Wenk // Starring Chris Makepeace and Grace Jones</p>
                            <p>Bring Me The Axe kicks off its Pride 2023 series with a mostly unremarkable movie that remains in the horror movie memory thanks entirely to contributions from gay artists, the 1986 horror comedy Vamp.</p>
                        </div>
                        <p className="episode-link">Listen &#62;&#62;</p>
                        <div className="episode-poster">
                            <div className="poster-overlay-50">&nbsp;</div>
                            <StaticImage
                                src="../images/vamp-poster-sm.jpeg"
                                quality={95}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="A poster for the 1986 horror comedy, Vamp"
                                className="img-fluid"
                                placeholder="BLURRED"
                                objectFit="cover"
                            />
                        </div>
                    </div>
                </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
              <Link to="/episodes/the-house-on-sorority-row">
                  <div className="episode">
                      <div className="episode-wrapper">
                          <h1 className="episode-title">The House on Sorority Row</h1>
                          <h2 className="episode-number">08</h2>
                          <div className="episode-body">
                              <p>1982 // Directed by Mark Rosman // Starring Mark Rosman and Eileen Davidson</p>
                              <p>Bryan and Dave close out their School's Out series, taking a look at the 1982 slasher picture, The House on Sorority Row, from director Mark Rosman, a hidden gem of the genre that remarkably passes the Bechdel Test.</p>
                          </div>
                          <p className="episode-link">Listen &#62;&#62;</p>
                          <div className="episode-poster">
                              <div className="poster-overlay-50">&nbsp;</div>
                              <StaticImage
                                  src="../images/house-on-sorority-row-poster-sm.jpeg"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="A poster for the 1982 slasher movie, The House On Sorority Row"
                                  className="img-fluid"
                                  placeholder="BLURRED"
                                  objectFit="cover"
                              />
                          </div>
                      </div>
                  </div>
              </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/final-exam">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">Final Exam</h1>
                            <h2 className="episode-number">07</h2>
                            <div className="episode-body">
                                <p>1981 // Directed by Jimmy Huston // Starring Cecile Bagdadi and Joel S. Rice</p>
                                <p>Bryan and Dave take a look at the 1981 slasher also-ran, Final Exam, a movie that should be stuffed down the horror movie memory hole were it not for its one secret weapon, a weirdo nerd character named Radish and his ongoing series of bizarre bon mots.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/final-exam-poster-sm.jpg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1981 slasher movie, Final Exam"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                    objectFit="cover"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/the-howling">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">The Howling</h1>
                            <h2 className="episode-number">06</h2>
                            <div className="episode-body">
                                <p>1981 // Directed by Joe Dante // Starring Dee Wallace and Patrick MacNee</p>
                                <p>Bryan and Dave break down one of the four werewolf movies released in 1981, Joe Dante's iconic The Howling. It's a wild ride made notable by its awesome special effects and dark comedic tone.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/the-howling-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1981 werewolf movie, The Howling"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
                <Link to="/episodes/the-sentinel">
                    <div className="episode">
                        <div className="episode-wrapper">
                            <h1 className="episode-title">The Sentinel</h1>
                            <h2 className="episode-number">05</h2>
                            <div className="episode-body">
                                <p>1977 // Directed by Michael Winner // Starring Cristina Raines and Chris Sarandon</p>
                                <p>Bryan and Dave take a look at 1977's The Sentinel. Ostensibly the most Catholic horror movie ever made, it's also about as paranoid as a horror movie can be. One damned soul, alone must stand watch over the gateway to Hell and Alison Parker is next in line.</p>
                            </div>
                            <p className="episode-link">Listen &#62;&#62;</p>
                            <div className="episode-poster">
                                <div className="poster-overlay-50">&nbsp;</div>
                                <StaticImage
                                    src="../images/the-sentinel-poster-sm.jpeg"
                                    quality={95}
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    alt="A poster for the 1977 haunted house movie, The Sentinel"
                                    className="img-fluid"
                                    placeholder="BLURRED"
                                />
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/maniac">
                <div className="episode">
                    <div className="episode-wrapper">
                        <h1 className="episode-title">Maniac</h1>
                        <h2 className="episode-number">04</h2>
                        <div className="episode-body">
                            <p>1980 // Directed by William Lustig // Starring Joe Spinell and Caroline Munro</p>
                            <p>This week Bryan and Dave take a look at William Lustig's 1980 roughie, Maniac, starring Joe Spinell and Caroline Munro. Bryan also goes off on a long tangent about the time he met Tom Savini and how it haunted Savini for years.</p>
                        </div>
                        <p className="episode-link">Listen &#62;&#62;</p>
                        <div className="episode-poster">
                            <div className="poster-overlay-50">&nbsp;</div>
                            <StaticImage
                                src="../images/maniac-poster-sm.jpeg"
                                quality={95}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="A poster for the 1980 slasher movie, Maniac"
                                className="img-fluid"
                                placeholder="BLURRED"
                            />
                        </div>
                    </div>
            </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/pieces">
            <div className="episode">
              <div className="episode-wrapper">
                <h1 className="episode-title">Pieces</h1>
                <h2 className="episode-number">03</h2>
                <div className="episode-body">
                  <p>1982 // Directed by Juan Piquer Simon // Starring Christopher George and Linda Day George</p>
                  <p>This week, Bryan and Dave take a look at the utterly deranged 1982 slasher picture, Pieces. This one has it all: Chainsaws, gratuitous nudity, buckets of blood, hysterical screaming, and THRILLING TENNIS ACTION! "It's exactly what you think it is."</p>
                </div>
                <p className="episode-link">Listen &#62;&#62;</p>
                <div className="episode-poster">
                  <div className="poster-overlay-75">&nbsp;</div>
                  <StaticImage
                    src="../images/pieces-poster-sm.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 slasher movie, Pieces"
                    className="img-fluid"
                    placeholder="BLURRED"
                  />
                </div>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/butcher-baker-nightmare-maker">
            <div className="episode">
              <div className="episode-wrapper">
                <h1 className="episode-title">Butcher, Baker, Nightmare Maker</h1>
                <h2 className="episode-number">02</h2>
                <div className="episode-body">
                  <p>1982 // Directed by William Asher // Starring Susan Tyrell and Jimmy McNichol</p>
                  <p>Campy doesn't even begin to cover this one. In what is becoming a disturbing trend at Bring Me The Axe, it's another story about inappropriate family love and the extent that a parent will go to keep their son close to them.</p>
                </div>
                <p className="episode-link">Listen &#62;&#62;</p>
                <div className="episode-poster">
                  <div className="poster-overlay-50">&nbsp;</div>
                  <StaticImage
                    src="../images/butcher-baker-nightmare-maker-poster-sm.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 horror movie, Butcher, Baker, Nightmare Maker"
                    className="img-fluid"
                    placeholder="BLURRED"
                  />
                </div>
              </div>
            </div>
            </Link>
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <Link to="/episodes/amityville-2-the-possession">
            <div className="episode">
              <div className="episode-wrapper">
                <h1 className="episode-title">Amityville 2: The Possession</h1>
                <h2 className="episode-number">01</h2>
                <div className="episode-body">
                  <p>1982 // Directed by Damiano Damiani // Starring Jack Magner and Diane Franklin</p>
                  <p>In this inaugural episode, Bring Me The Axe dives headfirst into the sequel to the original Amityville Horror phenomenon, 1982's Amityville 2: The Possession. Perhaps the most Italian horror movie made in America at the time, it's a movie about a brother and sister and the really weird, totally inappropriate sexual chemistry between them. And then the haunted stuff happens.</p>
                </div>
                <p className="episode-link">Listen &#62;&#62;</p>
                <div className="episode-poster">
                  <div className="poster-overlay-50">&nbsp;</div>
                  <StaticImage
                    src="../images/amityville-2-the-possession-poster-sm.jpg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1982 horror movie, Amityville 2: The Possession"
                    className="img-fluid"
                    placeholder="BLURRED"
                  />
                </div>
              </div>
            </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
)

export default AllEpisodes