import * as React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import { Seo } from "../components/seo"
import AllEpisodes from "../components/all-episodes"
import Subscribe from "../components/subscribe"

const IndexPage = () => (
  <Layout>
    <Header />
    <AllEpisodes />
  <Subscribe />
  <Footer />
  </Layout>
)

export default IndexPage

export const Head = () => (
    <Seo />
)